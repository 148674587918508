import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "badge badge-success"
}
const _hoisted_3 = {
  key: 1,
  class: "badge badge-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityTable = _resolveComponent("EntityTable")!

  return (_openBlock(), _createBlock(_component_EntityTable, {
    "entity-name": "Usuario",
    "resource-name": "users",
    "permission-prefix": "user_manage",
    "route-prefix": "user",
    "columns-data": _ctx.columns,
    "show-create": true,
    "show-edit": true,
    "show-pagination": true,
    "show-quickfilter": true,
    "show-export": true,
    "show-actions": true
  }, {
    "column-id": _withCtx((user) => [
      _createElementVNode("b", null, _toDisplayString(user.entity.id), 1)
    ]),
    "column-name": _withCtx((user) => [
      _createElementVNode("b", null, _toDisplayString(user.entity.firstName) + " " + _toDisplayString(user.entity.lastName), 1)
    ]),
    "column-role": _withCtx((user) => [
      (user.entity.roles.length > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(user.entity.roles[0].name), 1))
        : _createCommentVNode("", true)
    ]),
    "column-status": _withCtx((user) => [
      (user.entity.status === 'active')
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, " Activo "))
        : _createCommentVNode("", true),
      (user.entity.status === 'inactive')
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Inactivo "))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["columns-data"]))
}