
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";

import EntityTable from "@/components/EntityTable.vue";

export default defineComponent({
  name: "kt-widget-12",
  components: {
    EntityTable,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      columns: [
        {
          name: "id",
          label: "Id",
        },
        {
          name: "name",
          label: "Nombre Completo",
        },
        {
          name: "created_at",
          label: "Fecha de Creación",
        },
        {
          name: "updated_at",
          label: "Fecha de Modificación",
        },
        {
          name: "status",
          label: "Estado",
        },
        {
          name: "role",
          label: "Rol",
        },
      ],
    };
  },
});
